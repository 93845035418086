@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* a {
  text-decoration: none;
  color: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f77ff;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2986ff;
}

.main-background {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.main-background img {
  width: 100%;
  position: fixed;
  bottom: 0px;
  opacity: .4;
}

.MuiDataGrid-columnHeaders {
  background-color: #2986ff;
  color: #fff;
}

.MuiTreeItem-content {
  margin-top: 4px;
  border: solid 1px #2986ff;
  border-radius: 5px;
  width: 100px;
}

.MuiTreeItem-label {
  padding: 4px;
}